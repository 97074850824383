<script setup lang="ts">
	import { type Ref, ref, watch } from 'vue';
	import { required, minLength } from '@vuelidate/validators';
	import { useVuelidate } from '@vuelidate/core';
	import { useI18n } from 'vue-i18n';
	import MzWrapper from '@/components/MzWrapper.vue';
	import { MzButton, MzInput, MzPhone, MzSelect } from '@monizze/monizze-components';
	import { useUserStore } from '@/stores/user.ts';
	import MzCard from '@/components/MzCard.vue';
	import UserSidebar from '@/components/UserSidebar.vue';
	import { useAuthStore } from '@/stores/auth.ts';
	import { Api } from '@/utils/api.ts';
	import PhoneConfirm from '@/components/Mfa/PhoneConfirm.vue';
	import EmailConfirm from '@/components/Mfa/EmailConfirm.vue';
	import { isValid } from 'iban';
	import { countryCodes } from '@monizze/monizze-components/src/data/CountryCodes';
	import MzFetchMessage from '@/components/MzFetchMessage.vue';

	const { t } = useI18n();
	const userStore = useUserStore();
	const authStore = useAuthStore();
	const editMode: Ref<boolean> = ref(false);
	const editLogin: Ref<boolean> = ref(false);
	const editIban: Ref<boolean> = ref(false);
	const country: string = authStore.isLux ? 'LU' : 'BE';
	const displayedModal: Ref<string> = ref('');
	const validationCode: Ref<string> = ref('');
	const validationError: Ref<boolean> = ref(false);
	const payload: Ref<string> = ref('');
	const message = ref<InstanceType<typeof MzFetchMessage> | null>(null);

	const form = ref({
		firstname: '',
		lastname: '',
		birthdate: '',
		language: '',
	});
	const emailForm: Ref<string> = ref('');
	const phoneForm: Ref<string> = ref('');
	const emailError: Ref<boolean> = ref(false);
	const phoneError: Ref<boolean> = ref(false);
	const ibanForm: Ref<string> = ref('');
	const ibanError: Ref<string> = ref('');
	const selectedCountry: Ref<string> = ref(authStore.isLux ? 'LU' : 'BE');

	watch([selectedCountry, ibanForm], ([newCountry, newIban], [oldCountry, oldIban]) => {
		if (newCountry !== oldCountry) {
			ibanForm.value = '';
		}
		if (newIban !== oldIban) {
			ibanError.value = '';
		}
	});

	const rules = {
		firstname: { required, minLength: minLength(2) },
		lastname: { required, minLength: minLength(3) },
		birthdate: { required },
		language: { required },
	};

	const $v = useVuelidate(rules, form);

	/** @ts-ignore-next-line */
	const handleSubmit = async () => {
		$v.value.$touch();
		if (!$v.value.$invalid) {
			await Api.post('/user', form.value);
			userStore.user.firstname = form.value.firstname;
			userStore.user.lastname = form.value.lastname;
			userStore.user.birthdate = form.value.birthdate;
			userStore.user.language = form.value.language;
			editMode.value = false; // close the form when save is successful
		}
	};

	const handleLoginSubmit = (type: string, value: string) => {
		authStore
			.handleLoginUpdate(type, value)
			.then((response) => {
				payload.value = response.payload;
				emailError.value = false;
				phoneError.value = false;
				if (type === 'phone') {
					displayedModal.value = 'phone';
				} else {
					displayedModal.value = 'email';
				}
			})
			.catch(() => {
				if (type === 'phone') {
					phoneError.value = true;
				} else {
					emailError.value = true;
				}
			});
	};

	const handleConfirmLogin = async () => {
		authStore
			.handleLoginUpdateConfirm(payload.value, validationCode.value)
			.then(async () => {
				await userStore.refreshUser();
				validationCode.value = '';
				validationError.value = false;

				displayedModal.value = '';
				editLogin.value = false;
			})
			.catch(() => {
				validationError.value = true;
			});
	};

	const discardChanges = () => {
		form.value = {
			firstname: userStore.user.firstname || '',
			lastname: userStore.user.lastname || '',
			birthdate: userStore.user.birthdate || '',
			language: userStore.user.language,
		};
		editMode.value = false;
	};

	const startEdit = async () => {
		form.value = {
			firstname: userStore.user.firstname || '',
			lastname: userStore.user.lastname || '',
			birthdate: userStore.user.birthdate ? new Date(userStore.user.birthdate).toISOString().split('T')[0] : '',
			language: userStore.user.language,
		};
		editMode.value = true;
	};
	const editProfile = async () => {
		if (!(await userStore.isMfaGranted())) {
			return;
		}
		emailForm.value = userStore.user.email;
		phoneForm.value = userStore.user.phone;
		editLogin.value = true;
	};

	const editBanking = async () => {
		if (!(await userStore.isMfaGranted())) {
			return;
		}
		editIban.value = true;
	};

	const checkIBAN = () => {
		if (!isValid(ibanForm.value)) {
			ibanError.value = t('user.profile.banking.error');
			return;
		}
		Api.put('/user/iban', { iban: ibanForm.value })
			.then(() => {
				userStore.user.iban = ibanForm.value;
				editIban.value = false;
				message.value?.open({
					title: t('messages.iban.success.title'),
					description: t('messages.iban.success.description'),
					type: 'success',
				});
			})
			.catch(() => {
				editIban.value = false;
				message.value?.open({
					title: t('messages.iban.error.title'),
					description: t('messages.iban.error.description'),
					type: 'error',
				});
			});
	};

	const localeFlag = (locale: string): string => {
		if (locale === 'en') {
			locale = 'gb';
		}
		const codePoints = locale
			.toUpperCase()
			.split('')
			.map((char) => 127397 + char.charCodeAt(0));
		return String.fromCodePoint(...codePoints);
	};

	const enforceNumericWithPrefix = () => {
		const prefix = selectedCountry.value.toUpperCase();
		if (!ibanForm.value.startsWith(prefix)) {
			ibanForm.value = prefix + ibanForm.value.slice(2);
		}
		const numericPart = ibanForm.value.slice(2).replace(/\D/g, '');
		ibanForm.value = prefix + numericPart;
	};
</script>

<template>
	<mz-fetch-message ref="message" />
	<user-sidebar />
	<phone-confirm
		v-if="displayedModal === 'phone'"
		v-model="validationCode"
		:phone="phoneForm"
		:error="validationError"
		:allow-edit="false"
		@close="displayedModal = ''"
		@submit="handleConfirmLogin()"
		@resend="handleLoginSubmit('phone', phoneForm)"
	/>
	<email-confirm
		v-if="displayedModal === 'email'"
		v-model="validationCode"
		:email="emailForm"
		:error="validationError"
		:allow-edit="false"
		@close="displayedModal = ''"
		@submit="handleConfirmLogin()"
		@resend="handleLoginSubmit('email', emailForm)"
	/>
	<mz-wrapper :title="t('user.profile.title')" :intro="t('user.profile.subtitle')">
		<mz-card v-if="!editLogin" :title="t('user.profile.personal.title')">
			<template v-if="!editMode" #content>
				<div class="info-row">
					<p>{{ t('user.profile.personal.name') }}</p>
					<p>{{ userStore.user.firstname }}</p>
				</div>
				<div class="info-row">
					<p>{{ t('user.profile.personal.lastname') }}</p>
					<p>{{ userStore.user.lastname }}</p>
				</div>
				<div class="info-row">
					<p>{{ t('user.profile.personal.birthdate') }}</p>
					<p>{{ userStore.user.birthdate ? new Date(userStore.user.birthdate).toLocaleDateString() : '' }}</p>
				</div>
				<div class="info-row">
					<p>{{ t('user.profile.personal.language') }}</p>
					<p>{{ userStore.user.language }}</p>
				</div>

				<mz-button type="tertiary" :label="t('user.profile.personal.edit')" :width-auto="true" class="mt-6" @click="startEdit" />
			</template>
			<template v-else #content>
				<form class="flex flex-col gap-6">
					<mz-input
						v-model="form.firstname"
						:label="t('user.profile.personal.name')"
						:validated="!$v.firstname.$invalid"
						required
						minlength="2"
					/>
					<mz-input
						v-model="form.lastname"
						:label="t('user.profile.personal.lastname')"
						:validated="!$v.lastname.$invalid"
						required
						minlength="3"
					/>
					<mz-input
						v-if="!userStore.user.birthdate"
						v-model="form.birthdate"
						type="date"
						required
						:label="t('user.profile.personal.birthdate')"
					/>
					<mz-select
						v-model="form.language"
						:label="t('user.profile.personal.language')"
						:validated="!$v.language.$invalid"
						:options="authStore.availableLanguages.map((lang) => ({ label: t(`language.${lang}`), value: lang }))"
					></mz-select>
					<div class="flex sm:flex-row flex-col gap-6 sm:gap-10">
						<mz-button type="primary" :label="t('user.profile.personal.save')" :width-auto="true" @click.prevent="handleSubmit" />
						<mz-button type="minimal" :label="t('user.profile.personal.cancel')" :width-auto="true" @click="discardChanges" />
					</div>
				</form>
			</template>
		</mz-card>
		<mz-card v-if="!editLogin" class="mt-9" :title="t('user.profile.contact.title')">
			<template #content>
				<div class="info-row">
					<p>{{ t('user.profile.contact.email') }}</p>
					<p>{{ userStore.user.email }}</p>
				</div>
				<div class="info-row">
					<p>{{ t('user.profile.contact.phone') }}</p>
					<p>{{ userStore.user.phone }}</p>
				</div>
				<mz-button type="tertiary" :label="t('user.profile.contact.edit')" :width-auto="true" class="mt-6" @click="editProfile" />
			</template>
		</mz-card>
		<mz-card v-if="editLogin" :title="t('user.profile.contact.email')">
			<template #content>
				<form class="flex flex-col gap-6">
					<mz-input
						v-model="emailForm"
						:label="t('user.profile.contact.email')"
						required
						minlength="3"
						type="email"
						:validated="emailError ? false : undefined"
						:info="emailError ? t('user.profile.contact.invalid.email') : ''"
					/>
					<div class="flex sm:flex-row flex-col gap-6 sm:gap-10">
						<mz-button
							type="primary"
							:label="t('user.profile.personal.save')"
							:width-auto="true"
							@click.prevent="handleLoginSubmit('email', emailForm)"
						/>
						<mz-button type="minimal" :label="t('user.profile.personal.cancel')" :width-auto="true" @click="discardChanges" />
					</div>
				</form>
			</template>
		</mz-card>
		<mz-card v-if="editLogin" :title="t('login.form.phone')">
			<template #content>
				<form class="flex flex-col gap-6">
					<mz-phone
						id="phone"
						v-model="phoneForm"
						type="tel"
						:tab-index="1"
						:label="t('login.form.phone')"
						:country="country"
						:validated="phoneError ? false : undefined"
						:info="phoneError ? t('user.profile.contact.invalid.phone') : ''"
						autocomplete="phone"
						href="forgot-username"
						required
					/>
					<div class="flex sm:flex-row flex-col gap-6 sm:gap-10">
						<mz-button
							type="primary"
							:label="t('user.profile.personal.save')"
							:width-auto="true"
							@click.prevent="handleLoginSubmit('phone', phoneForm)"
						/>
						<mz-button type="minimal" :label="t('user.profile.personal.cancel')" :width-auto="true" @click="discardChanges" />
					</div>
				</form>
			</template>
		</mz-card>
		<mz-card v-if="!editIban && !authStore.isLux" class="mt-9" :title="t('user.profile.banking.title')">
			<template #content>
				<div class="info-row">
					<p>{{ t('user.profile.banking.label') }}</p>
					<p>{{ userStore.user.iban }}</p>
				</div>
				<mz-button type="tertiary" :label="t('user.profile.banking.edit')" :width-auto="true" class="mt-6" @click="editBanking" />
			</template>
		</mz-card>
		<mz-card v-if="editIban" :title="t('user.profile.banking.title')">
			<template #content>
				<form class="flex flex-col gap-10">
					<mz-select
						v-model="selectedCountry"
						:options="
							countryCodes.map((item) => ({ label: localeFlag(item.iso) + ' ' + t(`common.countryName.${item.iso}`), value: item.iso }))
						"
						:label="t('user.profile.banking.country')"
						required
					/>
					<mz-input
						v-model="ibanForm"
						type="text"
						:placeholder="selectedCountry.toUpperCase()"
						:label="t('user.profile.banking.label')"
						:info="ibanError"
						required
						:validated="ibanError ? false : undefined"
						@input="enforceNumericWithPrefix"
					/>
					<div class="flex sm:flex-row flex-col gap-6 sm:gap-10">
						<mz-button type="primary" :label="t('user.profile.personal.save')" :width-auto="true" @click.prevent="checkIBAN" />
						<mz-button type="minimal" :label="t('user.profile.personal.cancel')" :width-auto="true" @click="discardChanges" />
					</div>
				</form>
			</template>
		</mz-card>
	</mz-wrapper>
</template>

<style lang="sass" scoped>
	.info-row
		@apply flex w-full flex-wrap border-b border-global-border py-4 gap-4 sm:gap-0
		p:first-of-type
			@apply text-label-medium
		p:last-of-type
			@apply text-info-large
		p
			@apply w-full sm:w-1/2

	.info-row:last-of-type
		@apply border-none
</style>
