<script setup lang="ts">
	import { MzButton, MzInput, MzTitle, MzCheckbox, MzLink } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { useUserStore } from '@/stores/user.ts';
	import { computed, onBeforeMount, ref, type Ref } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import { useAuthStore } from '@/stores/auth.ts';
	import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
	import { useIntervalFn } from '@vueuse/core';

	const { t } = useI18n();
	const userStore = useUserStore();
	const validationCode: Ref<string> = ref('');
	const mfaError: Ref<boolean> = ref(false);
	const onLogin: Ref<boolean> = ref(false);
	const timeLeft: Ref<number> = ref(15);
	const router = useRouter();
	const route = useRoute();
	const isCard = computed(() => userStore.currentMfaMethod === 'card');
	const isTrustedDevice: Ref<boolean> = ref(false);
	const isWeakPassword: Ref<boolean> = ref(false);

	onBeforeMount(() => {
		if (typeof route.query.payload === 'string' && typeof route.query.method === 'string') {
			userStore.setRequestedMfa(route.query.payload, route.query.method);
			onLogin.value = true;
			isTrustedDevice.value = true;
		} else {
			if (route.query.single) {
				userStore.requestMfa(true);
				if (route.query.weakPassword) {
					isWeakPassword.value = true;
				}
			} else {
				userStore.requestMfa();
			}
		}
	});

	const { pause, resume, isActive } = useIntervalFn(() => {
		if (timeLeft.value > 0) timeLeft.value--;
		else {
			pause();
		}
	}, 1000);

	const submitMfa = async () => {
		try {
			mfaError.value = false;
			if (await userStore.activateMfa(validationCode.value, isTrustedDevice.value)) {
				if (isWeakPassword.value) {
					router.push({ name: 'weak-password', query: { verified: 'true' } });
					return;
				}
				if (userStore.currentMfaMethod === 'card') {
					// BR force MFA setup flow if not set
					await router.push({ name: 'user-security', query: { mfa: 'true' } });
					return;
				}
				if (typeof route.query.payload === 'string' && typeof route.query.method === 'string') {
					await router.push(useAuthStore().targetUrl);
					return;
				}
				router.back();
			}
		} catch (e) {
			mfaError.value = true;
		}
	};

	const resendCode = () => {
		userStore.requestMfa();
		timeLeft.value = 30;
		resume();
	};
</script>

<template>
	<main class="px-4">
		<section class="sm:max-w-[1200px] mx-auto w-full sm:py-20 py-6">
			<div class="card">
				<section>
					<img :src="useAuthStore().isLux ? '/up_luxembourg_logo.svg' : '/monizze_logo.svg'" alt="Logo" class="mb-10" />
					<a href="#" class="text-body-intro self-start hover:opacity-70" @click.prevent="router.back()">
						<FontAwesomeIcon :icon="faArrowLeft" class="pr-2" />
						{{ t('general.back') }}
					</a>
					<mz-title :text="t('user.mfa.confirm.enable')" class="pt-6"></mz-title>
				</section>
				<section>
					<div class="w-100 flex justify-center mb-10">
						<img :src="`/Mfa/${userStore.currentMfaMethod}.svg`" alt="Security image" />
					</div>
					<p v-if="userStore.user.user_id" class="text-body mb-6">
						{{ isCard ? t('user.mfa.confirm.card.request') : t(`user.mfa.confirm.code.request_${userStore.currentMfaMethod}`) }}
						<strong v-if="!isCard">{{ userStore.currentMfaMethod === 'email' ? userStore.user.email : userStore.user.phone }}</strong>
					</p>
					<p v-else class="text-body mb-6">
						{{ t(`user.mfa.confirm.code.${userStore.currentMfaMethod}`) }}
					</p>

					<div v-if="!isCard" class="flex flex-row gap-3 mb-5">
						<p>{{ t('duplicated2fa.page.not_received') }}</p>
						<mz-link
							:label="t(`duplicated2fa.page.resend_${userStore.currentMfaMethod}`)"
							href="#"
							:class="{ 'text-gray-400 cursor-not-allowed': isActive }"
							@click.prevent="!isActive && resendCode()"
						/>
						<p v-if="isActive" class="font-bold">{{ timeLeft }} s</p>
					</div>

					<mz-input
						v-model="validationCode"
						:info="mfaError ? (isCard ? t('user.mfa.confirm.card.error') : t('user.mfa.confirm.code.error')) : ''"
						:validated="mfaError ? false : undefined"
						class="mb-3"
						:label="isCard ? t('user.mfa.confirm.card.label') : t('user.mfa.confirm.code.label')"
						:placeholder="isCard ? t('user.mfa.confirm.card.placeholder') : t('user.mfa.confirm.code.label')"
						@keydown.enter="submitMfa"
					/>
					<mz-checkbox v-if="onLogin" v-model="isTrustedDevice">
						<template #label>{{ t('user.mfa.mark.device.trusted') }}</template>
					</mz-checkbox>
					<mz-button class="mt-7" :label="t('user.mfa.confirm.card.confirm')" :width-auto="true" @click.prevent="submitMfa" />
				</section>
			</div>
		</section>
	</main>
</template>

<style lang="sass" scoped>
	.card
		@apply flex md:flex-row flex-col sm:py-12 sm:px-10 py-6 px-4 items-start sm:gap-20 gap-6 rounded-lg border border-global-border bg-neutral-white
		section
			@apply md:w-1/2 w-full
</style>
